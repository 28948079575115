:root {
	--green: #57cc99;
	--dark: #040f0f;
    --pri: #e2e8f0;
    --sec: #bec3cc;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Poppins';
    box-sizing: border-box;
}

.site-shell {
    min-height: 100vh;
    overflow: hidden;
}

.home-shell {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--dark);
    min-height: 100vh;
}

.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 20px;
}

@media (min-width: 768px) {
    .home-container {
        padding: 60px 30px;
    }
}

@media (min-width: 992px) {
    .home-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1100px;
        padding: 10px 40px;
    }
}

@media (min-width: 1200px) {
    .home-container {
        padding: 0;
    }
}